<template>
  <div class="flex flex-col justify-center w-full m-auto content-center items-center py-8 md:py-16 space-y-8 min-h-screen max-h-screen">
    <div class="text-2xl text-center uppercase"> <img
        class="m-auto w-6"
        src="/img/logo-filled.svg"
      />
      <div class="mt-6">
        LOVEDTOPLAY<br> <span class="">Wall of Fame</span></div>
    </div>
    <div class="flex-col w-11/12 md:w-8/12 md:max-w-2xl overflow-scroll">
      <table
        class="table-auto w-full text-left leading-8 md:leading-10 text-center"
        v-if="scores"
      >
        <thead>
          <tr class="border-b border-black">
            <th></th>
            <th>NAME</th>
            <th>SCORE</th>
            <th>ZEIT</th>
            <th>DATUM</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-b border-black"
            v-for="(row, i) in tableData"
            :key="i"
          >
            <td class="uppercase overflow-hidden">{{i+1}}</td>
            <td class="uppercase overflow-hidden">{{ row.name }}</td>
            <td class="loved-font-bold">{{ row.score }}</td>
            <td>{{ row.time }}</td>
            <td>{{ row.created_at }}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="flex flex-row space-x-6 md:space-x-10">
      <button
        class="uppercase border-black border-1 rounded-lg border px-6 py-1 focus:outline-none"
        @click="$emit('restart')"
      >
        play again
      </button>
      <button
        class="uppercase border-black border-1 rounded-lg border px-6 py-1 focus:outline-none"
        @click="showAll = !showAll"
      >
        show <span v-if="!showAll">all</span><span v-else>less</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Highscore',
  props: ['scores'],
  data () {
    return {
      showAll: false
    }
  },
  computed: {
    tableData () {
      return [...this.scores].slice(0, (this.showAll) ? 100 : 10)
    }
  }
}
</script>

<style scoped>
.loved-row {
  height: 0.5em;
  background-image: url("/img/logo-filled-spaced.svg");
}
</style>
