<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-gray-800 opacity-50"></div>
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >&#8203;</span>
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="mounted"
          class="uppercase inline-block align-bottom bg-loved border-black border rounded-lg  px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div class="mx-auto flex items-center justify-center w-full p-8">
              <img src="/img/gamelogo.svg" />
            </div>
            <div class="text-center">
              <h3 class="text-2xl leading-6">
                Score
                <div class="text-4xl my-6">{{ score }}</div>
              </h3>
            </div>
          </div>
          <form
            @submit.prevent="submit"
            autocomplete="off"
          >
            <div class="mt-1 flex ">
              <label
                for="name"
                class="text-xl uppercase space-x-2 mr-4"
              >Name</label>
              <div class="relative flex items-stretch flex-grow focus-within:z-10">
                <input
                  v-model="name"
                  type="text"
                  minlength="3"
                  maxlength="12"
                  autocomplete="off"
                  autofocus
                  id="name"
                  class="text-center block border-b border-black w-full uppercase text-xl bg-loved outline-none"
                >
              </div>
              <button
                class="relative inline-flex items-center space-x-2 px-4 py-2 text-sm focus:outline-none"
                type="submit"
              >

                <img
                  src="/img/arrow.svg"
                  class="w-10"
                />
              </button>
            </div>
          </form>
          <!-- <div class="flex flex-row items-center justify-center mt-6">
            <button
              class="w-full uppercase border-black rounded-lg border text-sm px-4 md:px-8 py-0.5 md:py-1 md:text-xl focus:outline-none"
              @click="$emit('restart')"
            >
              Start Over
            </button>
          </div> -->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WinModal',
  methods: {
    setScore () {
      return fetch(`${window.location.href}score.php?n=${this.name}&s=${this.score}&t=${this.time}`).then(response => response.json())
    },
    submit () {
      if (!this.name?.length) return
      this.setScore().then((scores) => {
        this.$emit('show', scores)
      }).catch(() => this.$emit('error'))
    }
  },
  mounted () {
    this.mounted = true
    const win = new Audio("/sounds/winning.mp3");
    win.play();
  },
  data () {
    return {
      name: null,
      mounted: false
    }
  },
  computed: {
    score () {
      return parseInt(document.querySelector("#score .score").dataset.score);
    },
    time () {
      return document.querySelector("#score .timer span").textContent;
    }
  }
}
</script>


<style scoped>
</style>
