<template>
  <div
    id="game"
    class="flex flex-col min-h-screen z-50"
  >
    <div
      id="score"
      class="flex flex-row w-full uppercase items-center md:text-xl text-lg justify-between md:justify-end text-center leading-5 md:space-x-4"
    >
      <div class="flex-grow text-left hidden md:block">LOVEDTOPLAY</div>
      <div class="timer flex-shrink"> <label>Dauer </label><span
          class="loved-font-bold"
          id="time"
        >00:00</span></div>
      <div
        class="move-count"
        data-moves="0"
      >
        <label>Züge </label>
        <span class="loved-font-bold">0</span>
      </div>
      <div
        class="score"
        data-score="0"
      > <label>Punkte </label><span class="loved-font-bold">0</span></div>

    </div>
    <div class="flex-grow">
      <div id="table">
        <div class="upper-row">
          <div
            id="stock"
            class="stock pile"
            data-pile="stock"
          >
            <i
              class="reload-icon"
              data-action="reload"
            >
              <span></span>
            </i>
            <ul id="stocklist"></ul>
          </div>

          <div
            id="waste"
            class="waste pile"
            data-pile="waste"
          >
            <ul></ul>
          </div>

          <ul
            id="fnd"
            class="fnd"
          >
            <li
              id="spades"
              class="spades pile"
              data-pile="spades"
              data-empty="true"
            >
              <ul></ul>
            </li>
            <li
              id="hearts"
              class="hearts pile"
              data-pile="hearts"
              data-empty="true"
            >
              <ul></ul>
            </li>
            <li
              id="diamonds"
              class="diamonds pile"
              data-pile="diamonds"
              data-empty="true"
            >
              <ul></ul>
            </li>
            <li
              id="clubs"
              class="clubs pile"
              data-pile="clubs"
              data-empty="true"
            >
              <ul></ul>
            </li>
          </ul>
        </div>

        <div class="lower-row">
          <ul
            id="tab"
            class="tab"
          >
            <li
              class="pile"
              data-pile="1"
            >
              <ul></ul>
            </li>
            <li
              class="pile"
              data-pile="2"
            >
              <ul></ul>
            </li>
            <li
              class="pile"
              data-pile="3"
            >
              <ul></ul>
            </li>
            <li
              class="pile"
              data-pile="4"
            >
              <ul></ul>
            </li>
            <li
              class="pile"
              data-pile="5"
            >
              <ul></ul>
            </li>
            <li
              class="pile"
              data-pile="6"
            >
              <ul></ul>
            </li>
            <li
              class="pile"
              data-pile="7"
            >
              <ul></ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="w-full">
      <div class="flex m-auto flex-row items-center justify-center mb-4 space-x-4">
        <button
          class="uppercase border-black rounded-lg border text-sm px-4 md:px-8 py-0.5 md:py-1 md:text-xl focus:outline-none block sm:hidden"
          @click="$emit('restart')"
        >
          Restart
        </button>
        <button
          class="uppercase border-black rounded-lg border text-sm px-4 sm:px-8 py-0.5 sm:py-1 sm:text-lg focus:outline-none block sm:hidden"
          @click="$emit('show')"
        >
          Scores
        </button>
      </div>

      <div class="flex grid grid-cols-3">
        <div class="justify-self-start">
          <button
            class="uppercase border-black rounded-lg border text-sm px-4 sm:px-8 py-0.5 sm:py-1 sm:text-lg focus:outline-none sm:block hidden"
            @click="$emit('restart')"
          >
            Restart
          </button>

        </div>
        <div class="flex flex-shrink">
          <a
            href="https://www.loved.de"
            target="_blank"
            class="m-auto w-6 sm:w-8 "
          >
            <img src="/img/logo-filled.svg" />
          </a>

        </div>
        <div class="justify-self-end">
          <button
            class="uppercase border-black rounded-lg border text-sm px-4 sm:px-8 py-0.5 sm:py-1 sm:text-lg focus:outline-none sm:block hidden"
            @click="$emit('show')"
          >
            Scores
          </button>
        </div>
      </div>
      <div class="flex flex-row items-center justify-center"> <a
          href="https://www.loved.de"
          target="_blank"
        >
          LOVEDTOPLAY with love from loved
        </a></div>
    </div>
  </div>
</template>

<script>
const game = require('@/game.js').default

export default {
  name: 'Game',
  mounted () {
    game.start()
  }
}
</script>

<style>
.pile {
  /* background-size: contain; */
  background-repeat: no-repeat;
  background-image: url("/img/field-empty.svg");
}

.spades {
  background-image: url("/img/field-spade.svg");
}
.hearts {
  background-image: url("/img/field-heart.svg");
}
.diamonds {
  background-image: url("/img/field-diamond.svg");
}
.clubs {
  background-image: url("/img/field-club.svg");
}

#table {
  opacity: 0;
  width: 100%;
  padding: 15px 0;
}
#table > div {
  position: relative;
  margin-bottom: 10px;
}
#table > div:last-child {
  margin-bottom: 0;
}
#table > div::after {
  content: "";
  display: table-cell;
  clear: both;
}
#table ul {
  display: inline-block;
  padding: 0;
}
#table > div > ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pile {
  display: block;
  position: relative;
  float: left;
  width: 13%;
  margin-right: 1.5%;
  margin-bottom: 10px;
  padding: 5px;
}
.pile:last-child {
  margin-right: 0;
}
.pile::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("/img/cards/back.svg");
  /* background-size: contain; */
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  font-size: 0.85vw;
  border-radius: 10px;
  z-index: -1;
}
.waste .card {
  background: none;
}
.card * {
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .card {
    font-size: 0.4em;
  }
  .pile::after,
  .card {
    border-radius: 10px;
  }
}
@media screen and (min-width: 992px) {
  .card {
    font-size: 0.51em;
  }
}
@media screen and (min-width: 1200px) {
  .card {
    font-size: 0.54em;
  }
}
.card > div {
  display: none;
}
.card.up {
  /* background-image: url("https://bfa.github.io/solitaire-js/img/card_face_bg.png"); */
  background-repeat: no-repeat;
}
.card.up > div {
  display: block;
}
.card::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.card[data-selected="true"]::before {
  border: 2px solid black;
  border-radius: 1.5em;
}
@media screen and (min-width: 768px) {
  .card::before {
    border-radius: 10px;
  }
}
.card .suit {
  font-size: 5.8em;
  font-weight: normal;
  width: 0.6896551724137931em;
  height: 0.786206896551724em;
  line-height: 0.786206896551724em;
  position: absolute;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .card > div > .suit {
    display: none;
  }
}
.card .heart,
.card .diamond {
  color: #cc0000;
}
.card .spade .suit::before {
  content: "♠";
}
.card .heart .suit::before {
  content: "♥";
}
.card .diamond .suit::before {
  content: "♦";
}
.card .club .suit::before {
  content: "♣";
}
.card .corner {
  line-height: 1;
  position: absolute;
  text-align: center;
}
.card .corner span {
  display: block;
  font-size: 9em;
  font-weight: bold;
  width: 1em;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .card .corner span {
    font-size: 3em;
  }
}
.card .corner .suit {
  margin-top: 0;
  margin-left: 0;
}
.card .corner.top {
  left: 0.64em;
  top: 0.96em;
}
.card .corner.bottom {
  bottom: 0.96em;
  right: 0.64em;
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card .ace span.suit.middle_center {
  font-size: 10.24em;
  left: 50%;
  top: 50%;
  margin-top: -0.5em;
  margin-left: -0.35em;
}
.card .face::before {
  display: none;
  content: "";
  position: absolute;
  top: 15.25%;
  left: 19%;
  width: 62%;
  height: 70.5%;
  background-repeat: no-repeat;
  /* background-size: contain; */
}
@media screen and (min-width: 768px) {
  .card .face::before {
    display: block;
  }
}
.card .suit.top_center {
  left: 50%;
  top: 0;
  margin-left: -0.35em;
  margin-top: 0.65em;
}
.card .suit.top_left {
  left: 0;
  top: 0;
  margin-left: 0.65em;
  margin-top: 0.65em;
}
.card .suit.top_right {
  right: 0;
  top: 0;
  margin-right: 0.65em;
  margin-top: 0.65em;
}
.card .suit.middle_center {
  left: 50%;
  top: 50%;
  margin-left: -0.35em;
  margin-top: -0.5em;
}
.card .suit.middle_top {
  left: 50%;
  top: 0;
  margin-left: -0.35em;
  margin-top: 1.25em;
}
.card .suit.middle_bottom {
  bottom: 0;
  left: 50%;
  margin-bottom: 0.65em;
  margin-left: -0.35em;
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card .suit.middle_left {
  left: 0;
  top: 50%;
  margin-left: 0.65em;
  margin-top: -0.5em;
}
.card .suit.middle_right {
  right: 0;
  top: 50%;
  margin-right: 0.65em;
  margin-top: -0.5em;
}
.card .suit.middle_top_center {
  left: 50%;
  top: 50%;
  margin-left: -0.35em;
  margin-top: -1.35em;
}
.card .suit.middle_top_left {
  left: 0;
  top: 50%;
  margin-left: 0.65em;
  margin-top: -1em;
}
.card .suit.middle_top_right {
  right: 0;
  top: 50%;
  margin-right: 0.65em;
  margin-top: -1em;
}
.card .suit.middle_bottom_left {
  bottom: 50%;
  left: 0;
  margin-left: 0.65em;
  margin-bottom: -1em;
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card .suit.middle_bottom_right {
  bottom: 50%;
  right: 0;
  margin-bottom: -1em;
  margin-right: 0.65em;
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card .suit.middle_bottom_center {
  bottom: 50%;
  left: 50%;
  margin-bottom: -1.35em;
  margin-left: -0.35em;
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card .suit.bottom_center {
  bottom: 0;
  left: 50%;
  margin-bottom: 0.65em;
  margin-left: -0.35em;
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card .suit.bottom_left {
  bottom: 0;
  left: 0;
  margin-bottom: 0.65em;
  margin-left: 0.65em;
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card .suit.bottom_right {
  bottom: 0;
  right: 0;
  margin-bottom: 0.65em;
  margin-right: 0.65em;
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card:nth-child(1),
.card:nth-child(2),
.card:nth-child(3),
.card:nth-child(4),
.card:nth-child(5) {
  z-index: 1;
}
.card:nth-child(1) {
  top: 0;
  z-index: 5;
}
.card:nth-child(2) {
  top: 2px;
  z-index: 4;
}
.card:nth-child(3) {
  top: 4px;
  z-index: 3;
}
.card:nth-child(4) {
  top: 6px;
  z-index: 2;
}
.card:nth-child(5) {
  top: 8px;
  z-index: 1;
}

/* stock */
.stock {
  z-index: 1;
}
.stock .reload-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 3vw;
  font-weight: bold;
  line-height: 1;
  opacity: 0.25;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .stock .reload-icon {
    font-size: 5vw;
  }
}
.stock .reload-icon span {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0.25em;
  margin-top: -0.75em;
  pointer-events: none;
}
.stock .reload-icon span::before,
.stock .reload-icon span::after {
  content: "";
  display: inline-block;
  border-style: solid;
}
.stock .reload-icon span::before {
  width: 1.25em;
  height: 1.25em;
  border-color: transparent black black black;
  border-radius: 50%;
  border-width: 0.125em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.stock .reload-icon span::after {
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent black;
  border-width: 0.3125em 0 0.3125em 0.5em;
}

/* waste */
.waste {
  z-index: 1;
}

/* foundation */
.fnd .pile {
  left: 43.5%;
}
.fnd .pile::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #000;
  font-family: "Suit-Regular", sans-serif;
  font-size: 10vw;
  margin-top: -0.6em;
  line-height: 1;
  text-align: center;
  opacity: 0.25;
}
@media screen and (min-width: 768px) {
  .fnd .pile::before {
    font-size: 6em;
  }
}
@media screen and (min-width: 992px) {
  .fnd .pile::before {
    font-size: 7em;
  }
}
@media screen and (min-width: 1200px) {
  .fnd .pile::before {
    font-size: 8em;
  }
}

/* tableau */
.tab .card {
  z-index: 1;
  margin-bottom: 10em;
}
/* face up */
.tab .card:nth-child(2) {
  top: 6em;
  left: 0;
}
.tab .card:nth-child(3) {
  top: 12em;
  left: 0;
}
.tab .card:nth-child(4) {
  top: 18em;
  left: 0;
}
.tab .card:nth-child(5) {
  top: 24em;
  left: 0;
}
.tab .card:nth-child(6) {
  top: 30em;
  left: 0;
}
.tab .card:nth-child(7) {
  top: 36em;
  left: 0;
}
.tab .card:nth-child(8) {
  top: 42em;
  left: 0;
}
.tab .card:nth-child(9) {
  top: 48em;
  left: 0;
}
.tab .card:nth-child(10) {
  top: 54em;
  left: 0;
}
.tab .card:nth-child(11) {
  top: 60em;
  left: 0;
}
.tab .card:nth-child(12) {
  top: 66em;
  left: 0;
}
.tab .card:nth-child(13) {
  top: 72em;
  left: 0;
}
.tab .card:nth-child(14) {
  top: 78em;
  left: 0;
}
.tab .card:nth-child(15) {
  top: 84em;
  left: 0;
}
.tab .card:nth-child(16) {
  top: 90em;
  left: 0;
}
.tab .card:nth-child(17) {
  top: 96em;
  left: 0;
}
.tab .card:nth-child(18) {
  top: 102em;
  left: 0;
}
.tab .card:nth-child(19) {
  top: 108em;
  left: 0;
}
.tab .card:nth-child(20) {
  top: 114em;
  left: 0;
}
.tab .card:nth-child(21) {
  top: 120em;
  left: 0;
}
/* face down */
.tab .pile[data-unplayed="1"] .card:nth-child(2),
.tab .pile[data-unplayed="2"] .card:nth-child(2),
.tab .pile[data-unplayed="3"] .card:nth-child(2),
.tab .pile[data-unplayed="4"] .card:nth-child(2),
.tab .pile[data-unplayed="5"] .card:nth-child(2),
.tab .pile[data-unplayed="6"] .card:nth-child(2) {
  top: 3em;
}
.tab .pile[data-unplayed="2"] .card:nth-child(3),
.tab .pile[data-unplayed="3"] .card:nth-child(3),
.tab .pile[data-unplayed="4"] .card:nth-child(3),
.tab .pile[data-unplayed="5"] .card:nth-child(3),
.tab .pile[data-unplayed="6"] .card:nth-child(3) {
  top: 6em;
}
.tab .pile[data-unplayed="3"] .card:nth-child(4),
.tab .pile[data-unplayed="4"] .card:nth-child(4),
.tab .pile[data-unplayed="5"] .card:nth-child(4),
.tab .pile[data-unplayed="6"] .card:nth-child(4) {
  top: 9em;
}
.tab .pile[data-unplayed="4"] .card:nth-child(5),
.tab .pile[data-unplayed="5"] .card:nth-child(5),
.tab .pile[data-unplayed="6"] .card:nth-child(5) {
  top: 12em;
}
.tab .pile[data-unplayed="5"] .card:nth-child(6),
.tab .pile[data-unplayed="6"] .card:nth-child(6) {
  top: 15em;
}
.tab .pile[data-unplayed="6"] .card:nth-child(7) {
  top: 18em;
}
/* piles with odd # of face down cards */
.tab .pile[data-unplayed="1"] .card:nth-child(3) {
  top: 9em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(4),
.tab .pile[data-unplayed="3"] .card:nth-child(5) {
  top: 15em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(5),
.tab .pile[data-unplayed="3"] .card:nth-child(6),
.tab .pile[data-unplayed="5"] .card:nth-child(7) {
  top: 21em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(6),
.tab .pile[data-unplayed="3"] .card:nth-child(7),
.tab .pile[data-unplayed="5"] .card:nth-child(8) {
  top: 27em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(7),
.tab .pile[data-unplayed="3"] .card:nth-child(8),
.tab .pile[data-unplayed="5"] .card:nth-child(9) {
  top: 33em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(8),
.tab .pile[data-unplayed="3"] .card:nth-child(9),
.tab .pile[data-unplayed="5"] .card:nth-child(10) {
  top: 39em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(9),
.tab .pile[data-unplayed="3"] .card:nth-child(10),
.tab .pile[data-unplayed="5"] .card:nth-child(11) {
  top: 45em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(10),
.tab .pile[data-unplayed="3"] .card:nth-child(11),
.tab .pile[data-unplayed="5"] .card:nth-child(12) {
  top: 51em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(11),
.tab .pile[data-unplayed="3"] .card:nth-child(12),
.tab .pile[data-unplayed="5"] .card:nth-child(13) {
  top: 57em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(12),
.tab .pile[data-unplayed="3"] .card:nth-child(13),
.tab .pile[data-unplayed="5"] .card:nth-child(14) {
  top: 63em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(13),
.tab .pile[data-unplayed="3"] .card:nth-child(14),
.tab .pile[data-unplayed="5"] .card:nth-child(15) {
  top: 69em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(14),
.tab .pile[data-unplayed="3"] .card:nth-child(15),
.tab .pile[data-unplayed="5"] .card:nth-child(16) {
  top: 75em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(15),
.tab .pile[data-unplayed="3"] .card:nth-child(16),
.tab .pile[data-unplayed="5"] .card:nth-child(17) {
  top: 81em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(16),
.tab .pile[data-unplayed="3"] .card:nth-child(17),
.tab .pile[data-unplayed="5"] .card:nth-child(18) {
  top: 87em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(17),
.tab .pile[data-unplayed="3"] .card:nth-child(18),
.tab .pile[data-unplayed="5"] .card:nth-child(19) {
  top: 93em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(18),
.tab .pile[data-unplayed="3"] .card:nth-child(19),
.tab .pile[data-unplayed="5"] .card:nth-child(20) {
  top: 99em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(19),
.tab .pile[data-unplayed="3"] .card:nth-child(20),
.tab .pile[data-unplayed="5"] .card:nth-child(21) {
  top: 105em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(20),
.tab .pile[data-unplayed="3"] .card:nth-child(21) {
  top: 111em;
  left: 0;
}
.tab .pile[data-unplayed="1"] .card:nth-child(21) {
  top: 117em;
  left: 0;
}
/* piles with even # of face down cards */
.tab .pile[data-unplayed="2"] .card:nth-child(4) {
  top: 12em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(5),
.tab .pile[data-unplayed="4"] .card:nth-child(6) {
  top: 18em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(6),
.tab .pile[data-unplayed="4"] .card:nth-child(7),
.tab .pile[data-unplayed="6"] .card:nth-child(8) {
  top: 24em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(7),
.tab .pile[data-unplayed="4"] .card:nth-child(8),
.tab .pile[data-unplayed="6"] .card:nth-child(9) {
  top: 30em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(8),
.tab .pile[data-unplayed="4"] .card:nth-child(9),
.tab .pile[data-unplayed="6"] .card:nth-child(10) {
  top: 36em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(9),
.tab .pile[data-unplayed="4"] .card:nth-child(10),
.tab .pile[data-unplayed="6"] .card:nth-child(11) {
  top: 42em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(10),
.tab .pile[data-unplayed="4"] .card:nth-child(11),
.tab .pile[data-unplayed="6"] .card:nth-child(12) {
  top: 48em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(11),
.tab .pile[data-unplayed="4"] .card:nth-child(12),
.tab .pile[data-unplayed="6"] .card:nth-child(13) {
  top: 54em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(12),
.tab .pile[data-unplayed="4"] .card:nth-child(13),
.tab .pile[data-unplayed="6"] .card:nth-child(14) {
  top: 60em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(13),
.tab .pile[data-unplayed="4"] .card:nth-child(14),
.tab .pile[data-unplayed="6"] .card:nth-child(15) {
  top: 66em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(14),
.tab .pile[data-unplayed="4"] .card:nth-child(15),
.tab .pile[data-unplayed="6"] .card:nth-child(16) {
  top: 72em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(15),
.tab .pile[data-unplayed="4"] .card:nth-child(16),
.tab .pile[data-unplayed="6"] .card:nth-child(17) {
  top: 78em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(16),
.tab .pile[data-unplayed="4"] .card:nth-child(17),
.tab .pile[data-unplayed="6"] .card:nth-child(18) {
  top: 84em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(17),
.tab .pile[data-unplayed="4"] .card:nth-child(18),
.tab .pile[data-unplayed="6"] .card:nth-child(19) {
  top: 90em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(18),
.tab .pile[data-unplayed="4"] .card:nth-child(19),
.tab .pile[data-unplayed="6"] .card:nth-child(20) {
  top: 96em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(19),
.tab .pile[data-unplayed="4"] .card:nth-child(20),
.tab .pile[data-unplayed="6"] .card:nth-child(21) {
  top: 102em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(20),
.tab .pile[data-unplayed="4"] .card:nth-child(21) {
  top: 108em;
  left: 0;
}
.tab .pile[data-unplayed="2"] .card:nth-child(21) {
  top: 114em;
  left: 0;
}
/* Confetti */
#confetti {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  pointer-events: none;
  opacity: 0;
}
/* Disable Grammarly */
grammarly-card {
  display: none;
}
</style>
