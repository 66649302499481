<template>
  <div class="w-full h-full">
    <img
      class="hidden"
      id="scream"
      width="10"
      src="card.png"
      alt="The Scream"
    >
    <div class="static ...">
      <p>Static parent</p>
      <canvas
        class="z-10"
        id="can"
        ref="can"
      >
      </canvas>
      <div class="absolute bottom-0 left-0 z-auto">
        <p>Absolute child</p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Animation',
  data () {
    return {}
  }, methods: {
    animation (x = 0, y = 0, xfactor = 1, yfactor = 1) {
      var img = new Image();
      img.onload = () => {
        var c = document.getElementById("can");
        var ctx = c.getContext("2d");
        let size = window.innerWidth / 6
        ctx.canvas.width = window.innerWidth;
        ctx.canvas.height = window.innerHeight;
        let gap = window.innerWidth / 24
        let cardWith = window.innerWidth / 7
        let cardHeight = cardWith * 1.5
        let xcenter = window.innerWidth / 2
        let ycenter = window.innerHeight / 2
        let pos1 = xcenter - (gap / 2) - cardWith
        let pos2 = pos1 - gap - cardWith
        let pos3 = pos1 + gap + cardWith
        let pos4 = pos3 + gap + cardWith
        let ypos = ycenter - (cardHeight / 2)

        // ctx.drawImage(img, pos1, ypos, cardWith, cardHeight);
        // ctx.drawImage(img, pos2, ypos, cardWith, cardHeight);
        // ctx.drawImage(img, pos3, ypos, cardWith, cardHeight);
        // ctx.drawImage(img, pos4, ypos, cardWith, cardHeight);
        this.draw(img, pos1, 1, -1)
        this.draw(img, pos2, 1, -1)
        this.draw(img, pos3, -1, 1)
        this.draw(img, pos4, -1, 1)


      }
      img.src = "/cards/diamond-Q.svg";
    },
    draw (img, posx, xfactor = 1, yfactor = 1) {
      var c = document.getElementById("can");
      var ctx = c.getContext("2d");
      let size = window.innerWidth / 6

      let gap = window.innerWidth / 24
      let cardWith = window.innerWidth / 7
      let cardHeight = cardWith * 1.5
      let xcenter = window.innerWidth / 2
      let ycenter = window.innerHeight / 2
      let pos1 = xcenter - (gap / 2) - cardWith
      let pos2 = pos1 - gap - cardWith
      let pos3 = pos1 + gap + cardWith
      let pos4 = pos3 + gap + cardWith
      let ypos = ycenter - (cardHeight / 2)

      // ctx.drawImage(img, pos1, ypos, cardWith, cardHeight);
      // ctx.drawImage(img, pos2, ypos, cardWith, cardHeight);
      // ctx.drawImage(img, pos3, ypos, cardWith, cardHeight);
      // ctx.drawImage(img, pos4, ypos, cardWith, cardHeight);

      let timer = 20
      let count = 0;
      let max = 40
      let test = 400
      let interval = setInterval(() => {
        count++

        let mod = (count / max > 0.5) ? (1 - count / max) : (count / max)
        console.log(mod)
        let pos1calc = posx - (((max - count) * 10) * xfactor)

        //- cardWith - gap
        let countmod = count
        let maxmod = max
        let pos1ycalc = (ycenter - (cardHeight / 2)) + (((max - count) * 10) * yfactor)


        ctx.drawImage(img, pos1calc, pos1ycalc, cardWith, cardHeight);
        if (count >= max) clearInterval(interval)
      }, timer)
    },
    animatio (x = 0, y = 0, xfactor = 1, yfactor = 1) {


      var img = new Image();
      img.onload = function () {
        var c = document.getElementById("can");
        var ctx = c.getContext("2d");
        let size = window.innerWidth / 6
        ctx.canvas.width = window.innerWidth;
        ctx.canvas.height = window.innerHeight;
        let gap = window.innerWidth / 24
        let cardWith = window.innerWidth / 7
        let cardHeight = cardWith * 1.5
        let xcenter = window.innerWidth / 2
        let ycenter = window.innerHeight / 2
        let pos1 = xcenter - (gap / 2) - cardWith
        let pos2 = pos1 - gap - cardWith
        let pos3 = pos1 + gap + cardWith
        let pos4 = pos3 + gap + cardWith
        let ypos = ycenter - (cardHeight / 2)

        ctx.canvas.width = window.innerWidth;
        ctx.canvas.height = window.innerHeight;

        let timer = 10;
        let xcount = 1

        let count = 0;
        let max = 90
        let interval = setInterval(() => {

          let xcalc = count * (cardWith - xcount) * 0.1
          let ycalc = cardHeight - (max / 2 >= count) ? count * 3.2 : max - count
          ctx.drawImage(img, xcalc * xfactor - cardWith + x, ycalc * yfactor + y, cardWith, cardHeight);

          if (count > max) clearInterval(interval)
          count++
          xcount++
          if (count % 5) timer--
        }, timer)



      }
      img.src = "/cards/diamond-Q.svg";
      ;
    },
  },
  mounted () {
    this.animation(-250);
    this.animation(0, 650, 1, -1)

  },

}
</script>

<style scoped>
canvas {
  height: 100vh;
  width: 100vw;
  display: block;
}
</style>
