<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <transition
    enter-active-class="ease-out duration-300"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-200"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex justify-center min-h-screen text-center w-screen h-screen">

        <div
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-loved">
            <div class="h-screen flex py-6">
              <transition
                enter-active-class="ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div class="flex flex-col items-center m-auto self-center w-7/12 max-w-xl space-y-10 max-h-screen">
                  <img
                    src="/img/logo-filled.svg"
                    class="w-10"
                  />
                  <img
                    @click="cardClick"
                    src="/img/gamelogo.svg"
                    class="w-full max-w-lg"
                  />

                  <div
                    class="flex w-9/12 pt-6"
                    v-if="!isLoaded"
                  >
                    <div class="progress">
                      <div class="progress-value"></div>
                    </div>
                  </div>

                  <transition
                    enter-active-class="ease-out duration-500"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                  >

                    <div
                      v-if="isLoaded"
                      class="uppercase md:text-3xl text-xl w-full space-y-10"
                    >
                      <div>starring</div>

                      <div
                        class="grid grid-cols-4 no-wrap uppercase leading-5 text-xs sm:text-md md:text-lg gap-4"
                        @click="cardClick"
                      >

                        <div class="flip-card rotate">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <img src="/img/cards/back.svg">
                            </div>
                            <div class="flip-card-back">
                              <img src="/img/cards/spade-J.svg">
                              <div class="mt-3">Maik <br> Beimdieck</div>

                            </div>
                          </div>
                        </div>
                        <div class="flip-card rotate">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <img src="/img/cards/back.svg">
                            </div>
                            <div class="flip-card-back">
                              <img src="/img/cards/spade-Q.svg">
                              <div class="mt-3">Sabine <br> Cole</div>
                            </div>
                          </div>
                        </div>
                        <div class="flip-card rotate">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <img src="/img/cards/back.svg">
                            </div>
                            <div class="flip-card-back">
                              <img src="/img/cards/spade-K.svg">
                              <div class="mt-3"> Michael <br> Jacobs</div>
                            </div>
                          </div>
                        </div>
                        <div class="flip-card rotate">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <img src="/img/cards/back.svg">
                            </div>
                            <div class="flip-card-back">
                              <img src="/img/cards/spade-A.svg">
                              <div class="mt-3"> Mieke <br> Haase</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        class="uppercase border-black rounded-lg border px-16 py-1 text-2xl focus:outline-none opacity-0 mt-10"
                        @click="$emit('start')"
                      >
                        Play
                      </button>
                    </div>
                  </transition>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'SplashScreen',
  data () {
    return {
      timer: +new Date,
      loading: 0,
      cards: [],
    }
  },
  methods: {
    cardClick () {
      if (+new Date - this.timer > 8000) this.$emit('start')
    },
    preload () {
      setTimeout(() => {
        const interval = setInterval(() => {
          if (this.loading === this.cards.length) return clearInterval(interval)
          let img = new Image()
          img.src = this.cards[this.loading]
          // img.onload = () => {
          //   console.log('img loaded', this.loading, this.cards[this.loading]);
          // }
          this.loading++
        }, 50);
      }, 500)
    }
  },
  created () {
    ['A', 'K', 'Q', 'J', '10', '9', '8', '7', '6', '5', '4', '3', '2'].forEach(card => {
      ['spade', 'diamond', 'heart', 'club'].forEach(suit => {
        this.cards.push(`/img/cards/${suit}-${card}.svg`)
      })
    })
  },
  mounted () {
    this.preload()
  },
  computed: {
    isLoaded () {
      return this.loading >= this.cards.length
    }
  },
}
</script>


<style scoped>
.progress {
  background: black;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: flex;
  height: 1px;
  width: 500px;
}

.progress-value {
  animation: load 3s normal forwards;
  border-radius: 100px;
  background: black;
  height: 3px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

button {
  animation-delay: 4.4s;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.flip-card:nth-last-of-type(1) .flip-card-inner {
  animation-delay: 3.5s;
}
.flip-card:nth-last-of-type(2) .flip-card-inner {
  animation-delay: 2.5s;
}
.flip-card:nth-last-of-type(3) .flip-card-inner {
  animation-delay: 1.5s;
}
.flip-card:nth-last-of-type(4) .flip-card-inner {
  animation-delay: 0.5s;
}

.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  animation-name: rotate;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  width: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  position: inherit;
}

.flip-card-back {
  transform: rotateY(180deg);
}
</style>
