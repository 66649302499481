<template>
  <div
    id="app"
    class="select-none"
  >
    <splash-screen
      v-if="showSplash"
      v-on:start="showSplash = false; showGame = true"
    />
    <win-modal
      v-if="showWinModal"
      v-on:show="showScores"
      v-on:error="showWinModal = false"
      v-on:restart="restart"
    />
    <animation v-if="showAnimation" />
    <div class="flex flex-col w-full min-h-screen">
      <div
        class="flex-grow bg-grey-400 m-auto w-11/12 md:w-9/12 max-w-4xl"
        v-if="showGame"
      >

        <game
          class="py-4"
          v-on:restart="restart"
          v-on:win="showWinModal = true"
          v-on:show="getScores"
        />

      </div>
      <!-- <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      > -->

      <highscore
        :scores="scores"
        v-if="showScore"
        v-on:restart="restart"
      />
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
import Animation from './components/Animation.vue';
import SplashScreen from './components/SplashScreen.vue';
import Highscore from './components/Highscore.vue';
import Game from './components/Game.vue'
import WinModal from './components/WinModal.vue';

export default {
  name: 'App',
  components: {
    Highscore,
    SplashScreen,
    Game,
    Animation,
    WinModal
  },
  mounted () {
    document.addEventListener('win', (e) => {
      this.win()
    })
  },
  methods: {
    getScores () {
      fetch(`${window.location.href}score.php`).then(response => response.json()).then(scores => {
        this.scores = scores
        this.showGame = false
        this.showScore = true
        this.showWinModal = false
      })
    },
    showScores (scores) {
      this.scores = scores
      this.showGame = false
      this.showScore = true
      this.showWinModal = false
    },
    win () {
      console.log('win')
      this.showWinModal = true
    },
    restart () {
      this.showScore = false
      this.showWinModal = false
      this.showGame = false
      this.$nextTick(() => this.showGame = true)
    },
  },
  data () {
    return {
      scores: [],
      showWinModal: false,
      showGame: false,
      showSplash: true,
      showScore: false,
      showAnimation: false,
    }
  }
}
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #f7c8dc;
}

.selected {
  border: 2px solid black;
  border-radius: 15px;
}
</style>
